import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./views/App";
import * as serviceWorker from "./serviceWorker";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<App />} />
  </Routes>
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppRoutes />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
