import React, { useState } from "react";
import InputMask from "react-input-mask";
import axios from "axios";

const initialState = {
  name: "",
  email: "",
  phone: "",
  bestcontact: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, phone, bestcontact, message }, setState] =
    useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedPhone = phone.replace(/[^\d]/g, "");
    alert("Obrigado pelo contato, em breve retornaremos...");

    const formData = {
      name: name,
      email: email,
      phone: cleanedPhone,
      bestcontact: bestcontact,
      message: message,
    };

    try {
      const response = await axios.post(
        "https://hml.gestao.twnsistemas.com.br/api/lead-lading-email?twn-key=!2024TWNzyx*",
        formData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
    window.location.href = "/";
  };

  return (
    <div>
      <div id="contact" className="background-[#2f455c]">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Entre em contato:</h2>
                <p>
                  Por favor, preencha seus dados de contato que retornaremos o
                  mais rápido possível!
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="E-mail"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <InputMask
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Telefone"
                        required
                        mask="(99) 99999-9999"
                        maskChar="_"
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        id="bestcontact"
                        name="bestcontact"
                        className="form-control"
                        required
                        onChange={handleChange}
                        value={bestcontact}
                      >
                        <option value="" disabled hidden>
                          Melhor Forma de Contato
                        </option>
                        <option value="whatsapp">Whatsapp</option>
                        <option value="ligacao">Ligação</option>
                        <option value="sms">SMS</option>
                        <option value="email">Email</option>
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensagem"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensagem
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de Contato:</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefone
                </span>
                <div style={{ display: "flex" }}>
                  <a
                    href={`https://wa.me//+5551996058802`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p style={{ color: "white", fontSize: "16px" }}>
                      {props.data ? props.data.phone : "loading"}
                    </p>
                  </a>
                  <p
                    style={{
                      color: "white",
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  >
                    ou
                  </p>
                  <p style={{ color: "white", fontSize: "16px" }}>3069-2422</p>
                </div>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> E-mail
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a
                  href="https://www.instagram.com/twnsistemas/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <i className="fa fa-instagram"></i> Instagram
                  </span>
                  <p style={{ color: "white", fontSize: "16px" }}>
                    @twnsistemas
                  </p>
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="social">
              <ul>
                <li>
                  <a
                    href={`https://wa.me//+5551996058802`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <i className="fa fa-whatsapp"></i>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/twnsistemas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <i className="fa fa-instagram"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; TWN Sistemas, 2023.</p>
        </div>
      </div>
    </div>
  );
};
